import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Button, styled, TextField, Tooltip, tooltipClasses } from '@mui/material';
import data from './labels.json';
import examples from './examples.json';
import frequency from './frequency.json';
import './App.css';
import Result from './components/Result';
import _ from 'underscore';
import AlternatingImage from './components/AlternatingImage';
import InfoIcon from '@mui/icons-material/Info';

// Helpers
const getBranches = ({_type='', _avoidable='', _class='', _group='', _subgroup='', _description='', _transformed='', _dish=''}) => {
    let keys_source = data;
    if(_type) keys_source = keys_source[_type];
    if(_avoidable) keys_source = keys_source[_avoidable];
    if(_class) keys_source = keys_source[_class];
    if(_group) keys_source = keys_source[_group];
    if(_subgroup) keys_source = keys_source[_subgroup];
    if(_description) keys_source = keys_source[_description];
    if(_transformed) keys_source = keys_source[_transformed];
    if(_dish) keys_source = keys_source[_dish];

    if (typeof(keys_source) === 'string') {  // This means we have reached a valid label!
        return [];
    }

    return Object.keys(keys_source);
};

const getAllLabels = () => {
    let source = data;

    const iterateToKeys = (keys, object) => {
        Object.values(object).forEach(value => {
            if(typeof(value) !== 'string'){
                iterateToKeys(keys, value);
            } else {
                keys.push(value);
            }
        });
    };

    const subLabels = [];
    if(typeof(source) !== 'string'){
        iterateToKeys(subLabels, source);
    } else {
        subLabels.push(source);
    }

    return subLabels;
}

const allLabels = getAllLabels();

const getSubLabels = ({_type, _avoidable, _class, _group, _subgroup, _description, _transformed, _dish}) => {
    let source = data;

    if(_type) source = source[_type];
    if(_avoidable) source = source[_avoidable];
    if(_class) source = source[_class];
    if(_group) source = source[_group];
    if(_subgroup) source = source[_subgroup];
    if(_description) source = source[_description];
    if(_transformed) source = source[_transformed];
    if(_dish) source = source[_dish];

    if(!source) return [];

    const iterateToKeys = (keys, object) => {
        Object.values(object).forEach(value => {
            if(typeof(value) !== 'string'){
                iterateToKeys(keys, value);
            } else {
                keys.push(value);
            }
        });
    };

    const subLabels = [];
    if(typeof(source) !== 'string'){
        iterateToKeys(subLabels, source);
    } else {
        subLabels.push(source);
    }

    return subLabels;
}

const getExamples = ({_type, _avoidable, _class, _group, _subgroup, _description, _transformed, _dish}) => {
    const subLabels = getSubLabels({_type, _avoidable, _class, _group, _subgroup, _description, _transformed, _dish});

    const events = [];
    subLabels.forEach(label => {
        if(examples[label]) events.push(...examples[label])
    })

    return events;
}

const getFrequency = ({_type, _avoidable, _class, _group, _subgroup, _description, _transformed, _dish}) => {
    const subLabels = getSubLabels({_type, _avoidable, _class, _group, _subgroup, _description, _transformed, _dish});

    let acc = 0;
    subLabels.forEach(label => {
        if(frequency[label]) acc += frequency[label];
    })

    return acc;
}

// Magic hook that detects if element is in viewport; idk how it works
function useOnScreen(ref) {

  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(() => new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
    // eslint-disable-next-line
  ), [ref])


  useEffect(() => {
    observer.observe(ref.current)
    return () => observer.disconnect()
    // eslint-disable-next-line
  }, [])

  return isIntersecting
}

// App
function App() {
    const [selected, setSelected] = useState({
        '_type': '',
        '_avoidable': '',
        '_class': '',
        '_group': '',
        '_subgroup': '',
        '_description': '',
        '_transformed': '',
        '_dish': '' // TODO: Fix this awful patchup
    });
    const [label, setLabel] = useState('');
    const [explore, setExplore] = useState(false);
    const [search, setSearch] = useState('');
    const [filtered, setFiltered] = useState(allLabels);

    const selectLabel = useMemo(() => ({_type='', _avoidable='', _class='', _group='', _subgroup='', _description='', _transformed='', _dish=''}) => {
        let to_set_label = '';
        let source = data;

        if(_type){
            source = source[_type];
            if(typeof(source) === 'string') to_set_label = source;
            if(Object.keys(source).length === 1) _avoidable = Object.keys(source)[0];
        }

        if(_avoidable){
            source = source[_avoidable];
            if(typeof(source) === 'string') to_set_label = source;
            if(Object.keys(source).length === 1) _class = Object.keys(source)[0];
        }

        if(_class){
            source = source[_class];
            if(typeof(source) === 'string') to_set_label = source;
            if(Object.keys(source).length === 1) _group = Object.keys(source)[0];
        }

        if(_group){
            source = source[_group];
            if(typeof(source) === 'string') to_set_label = source;
            if(Object.keys(source).length === 1) _subgroup = Object.keys(source)[0];
        }

        if(_subgroup){
            source = source[_subgroup];
            if(typeof(source) === 'string') to_set_label = source;
            if(Object.keys(source).length === 1) _description = Object.keys(source)[0];
        }

        if(_description){
            source = source[_description];
            if(typeof(source) === 'string') to_set_label = source;
            if(Object.keys(source).length === 1) _transformed = Object.keys(source)[0];
        }

        if(_transformed){
            source = source[_transformed];
            if(typeof(source) === 'string') to_set_label = source;
            if(Object.keys(source).length === 1) _dish = Object.keys(source)[0];
        }

        if(_dish){
            source = source[_dish];
            if(typeof(source) === 'string') to_set_label = source;
        }

        if(to_set_label !== label) {
            setLabel(to_set_label);
        }

        setSelected({
            '_type': _type,
            '_avoidable': _avoidable,
            '_class': _class,
            '_group': _group,
            '_subgroup': _subgroup,
            '_description': _description,
            '_transformed': _transformed,
            '_dish': _dish,
        });

    }, [setSelected, label, setLabel]);

    useEffect(() => {
        setFiltered(allLabels.filter(label => label.toLowerCase().includes(search.toLowerCase())));
    }, [search, setFiltered]);

    return (
        <div className='main'>
            Click on an image to see more details
            <div className='buttons'>
                <Button style={{borderTopRightRadius: '0px', borderBottomRightRadius: '0px'}} disableElevation variant={!explore ? 'contained' : 'outlined'} onClick={() => {
                    setExplore(false);
                    setSelected({
                        '_type': '',
                        '_avoidable': '',
                        '_class': '',
                        '_group': '',
                        '_subgroup': '',
                        '_description': '',
                        '_transformed': '',
                        '_dish': ''
                    });
                }}>default</Button>
                <Button style={{borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px'}} disableElevation variant={explore ? 'contained' : 'outlined'} onClick={() => setExplore(true)}>All labels</Button>
            </div>

            {label
            ? <Result label={label} setSelected={setSelected} setLabel={setLabel} />
            : (
                explore
                ? <div className='optionContainer'>
                    <TextField style={{marginBottom: '10px'}} value={search} onChange={e => setSearch(e.target.value)} label='Search' />
                    <OptionsBlock
                        condition={filtered}
                        labels={filtered}
                        trashevents={label => (examples[label] || [])}
                        frequency={label => (frequency[label] || 0)}
                        selectLabel={label => () => setLabel(label)}
                        explorer
                    />
                </div>
                : <div className='optionContainer'>
                    <OptionsBlock
                        onSelection={label => ({'_type': label})}
                        condition={!selected['_type']}
                        labels={getBranches(selected)}
                        trashevents={label => getExamples({'_type': label})}
                        frequency={label => getFrequency({'_type': label})}
                        selectLabel={label => () => selectLabel({'_type': label})}
                    />
                    <OptionsBlock
                        onSelection={label => ({...selected, '_avoidable': label})}
                        condition={selected['_type'] && !selected['_avoidable']}
                        labels={getBranches(selected)}
                        trashevents={label => getExamples({...selected, '_avoidable': label})}
                        frequency={label => getFrequency({...selected, '_avoidable': label})}
                        selectLabel={label => () => selectLabel({...selected, '_avoidable': label})}
                        goBack={() => selectLabel({})}
                    />
                    <OptionsBlock
                        onSelection={label => ({...selected, '_class': label})}
                        condition={selected['_avoidable'] && !selected['_class']}
                        labels={getBranches(selected)}
                        trashevents={label => getExamples({...selected, '_class': label})}
                        frequency={label => getFrequency({...selected, '_class': label})}
                        selectLabel={label => () => selectLabel({...selected, '_class': label})}
                        goBack={() => selectLabel({...selected, '_avoidable': ''})}
                    />
                    <OptionsBlock
                        onSelection={label => ({...selected, '_group': label})}
                        condition={selected['_class'] && !selected['_group']}
                        labels={getBranches(selected)}
                        trashevents={label => getExamples({...selected, '_group': label})}
                        frequency={label => getFrequency({...selected, '_group': label})}
                        selectLabel={label => () => selectLabel({...selected, '_group': label})}
                        goBack={() => selectLabel({...selected, '_class': ''})}
                    />
                    <OptionsBlock
                        onSelection={label => ({...selected, '_subgroup': label})}
                        condition={selected['_group'] && !selected['_subgroup']}
                        labels={getBranches(selected)}
                        trashevents={label => getExamples({...selected, '_subgroup': label})}
                        frequency={label => getFrequency({...selected, '_subgroup': label})}
                        selectLabel={label => () => selectLabel({...selected, '_subgroup': label})}
                        goBack={() => selectLabel({...selected, '_group': ''})}
                    />
                    <OptionsBlock
                        onSelection={label => ({...selected, '_description': label})}
                        condition={selected['_subgroup'] && !selected['_description']}
                        labels={getBranches(selected)}
                        trashevents={label => getExamples({...selected, '_description': label})}
                        frequency={label => getFrequency({...selected, '_description': label})}
                        selectLabel={label => () => selectLabel({...selected, '_description': label})}
                        goBack={() => selectLabel({...selected, '_subgroup': ''})}
                    />
                    <OptionsBlock
                        onSelection={label => ({...selected, '_transformed': label})}
                        condition={selected['_description'] && !selected['_transformed']}
                        labels={getBranches(selected)}
                        trashevents={label => getExamples({...selected, '_transformed': label})}
                        frequency={label => getFrequency({...selected, '_transformed': label})}
                        selectLabel={label => () => selectLabel({...selected, '_transformed': label})}
                        goBack={() => selectLabel({...selected, '_description': ''})}
                    />
                    <OptionsBlock
                        onSelection={label => ({...selected, '_dish': label})}
                        condition={selected['_transformed'] && !selected['_dish']}
                        labels={getBranches(selected)}
                        trashevents={label => getExamples({...selected, '_dish': label})}
                        frequency={label => getFrequency({...selected, '_dish': label})}
                        selectLabel={label => () => selectLabel({...selected, '_dish': label})}
                        goBack={() => selectLabel({...selected, '_transformed': ''})}
                    />
                </div>
            )
            }</div>
    );
}


function OptionsBlock({condition, onSelection, trashevents, frequency, labels, selectLabel, goBack, explorer}){
    return condition && labels.sort((a, b) => frequency(b) - frequency(a)).map(
        label => <Option key={label} onSelection={onSelection && onSelection(label)} explorer={explorer} totLabels={labels.length} label={label} trashevents={trashevents(label)} frequency={frequency(label)} selectLabel={selectLabel(label)} goBack={goBack} />
    );
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: '#333',
    display: 'flex',
    flexDirection: 'column',
    flexFlox: 'column wrap',
    justifyContent: 'flex-start',
    maxHeight: '300px',
    minWidth: '500px',
    overflowY: 'scroll',
    fontSize: '15px',
    borderRadius: '10px'
  },
}));

function Option({label, onSelection, totLabels, trashevents, frequency, selectLabel, goBack, explorer}){
    const [examples, setExamples] = useState(null);
    const [exclude, setExclude] = useState([]);
    const [show, setShow] = useState(explorer ? false : totLabels <= 2);
    const ref = useRef(null);
    const isVisible = useOnScreen(ref);
    const [subLabels, setSubLabels] = useState([]);

    useEffect(() => {
        if(explorer) {
            if(isVisible) setShow(true);
        }
    }, [isVisible, setShow, explorer]);

    useEffect(() => {
        setExamples(_.sample(trashevents.filter(event => !exclude.includes(event)), 3));
    }, [trashevents, exclude, setExamples]);

    useEffect(() => {
        if(onSelection) setSubLabels(getSubLabels(onSelection).sort().map(name => <div>{name}<br /></div>));
    }, [setSubLabels, onSelection]);

    return <div key={label} className='option' ref={ref}>
        <div className='option-header'>
            <h2>
                <div className='tooltip'>
                    {label}
                    {subLabels.length > 1 && <HtmlTooltip
                        className='tooltip-content'
                        placement='right-start'
                        title={
                            <div>
                                <h4 style={{margin: '0'}}>This group contains:</h4>
                                {subLabels}
                            </div>
                        }>
                        <InfoIcon color='primary' style={{margin: '0 0 0 3px', fontSize: '15px'}} />
                    </HtmlTooltip>}
                </div>
                <div className='faded'>{frequency.toFixed(2)}% frequency</div>
            </h2>
            {goBack && <Button
                variant="contained"
                disableElevation
                size='small'
                style={{marginRight: '5px'}}
                onClick={goBack}>
                Back
            </Button>}
            {show && <Button
                variant="contained"
                disableElevation
                size='small'
                style={{marginRight: '5px'}}
                onClick={() => setExclude(events => {
                    const _events = [...events];
                    examples.forEach(event => _events.push(event));
                    return _events;
                })}>
                More examples
            </Button>}
            <Button
                variant="contained"
                disableElevation
                size='small'
                style={{marginRight: '5px'}}
                onClick={() => setShow(s => !s)}>
                {show ? 'Hide examples' : 'Show examples'}
            </Button>
            <Button
                variant="contained"
                disableElevation
                size='small'
                disabled={!label}
                onClick={selectLabel}>
                Select
            </Button>
        </div>
        {show && (
            trashevents?.length
            ? (
                examples?.length
                ? <div className='images'>{examples.map(trashevent => <AlternatingImage key={trashevent} trashevent_id={trashevent} />)}</div>
                : <Button variant="contained" disableElevation size='small' onClick={() => setExclude([])}>Reset examples</Button>
            )
            : 'No examples'
        )}
    </div>;
}

export default App;
