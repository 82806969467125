import { useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import examples from '../examples.json';

function AlternatingImage({trashevent_id}) {
    const [before, setBefore] = useState(true);
    const [maskExists, setMaskExists] = useState(true);
    const [focusedEvent, setFocusedEvent] = useState(null);

    useEffect(() => {
        const timer = setTimeout(() => setBefore(data => !data), 1000);
        return () => clearTimeout(timer);
    }, [before, setBefore]);

    return focusedEvent
        ? <ZoomedImage focusedEvent={focusedEvent} setFocusedEvent={setFocusedEvent} />
        : <div onClick={() => setFocusedEvent(trashevent_id)} className="exampleContainer">
            <img
                src={
                    maskExists
                    ? `https://label-picker-trashevents.s3.eu-central-1.amazonaws.com/${trashevent_id}.mask.jpg`
                    : `https://label-picker-trashevents.s3.eu-central-1.amazonaws.com/${trashevent_id}.${before ? "before" : "after"}.png`
                }
                onError={({currentTarget}) => {
                    currentTarget.onerror = null;
                    setMaskExists(false);
                }}
            />
            <div>{Object.keys(examples).find(name => examples[name].includes(trashevent_id))}</div>
        </div>;
}

const ZOOM_MAX = 50;
const ZOOM_MIN = 10;
const ZOOM_DELTA = 1;

function ZoomedImage({focusedEvent, setFocusedEvent}){
    const [before, setBefore] = useState(false);
    const [originalTag, setOriginalTag] = useState(null);
    const [imageStyle, setImageStyle] = useState({});
    const [zoom, setZoom] = useState(ZOOM_MIN);
    const image = useRef(null);

    useEffect(() => {
        setOriginalTag(Object.keys(examples).find(name => examples[name].includes(focusedEvent)));
    }, []);

    useEffect(() => {
        if(image){
            const onWheel = e => {
                e.preventDefault();
                if(e.deltaY < 0) {
                    setZoom(currZoom => {
                        if(currZoom <= ZOOM_MAX - ZOOM_DELTA) {
                            return currZoom + ZOOM_DELTA;
                        }

                        return currZoom;
                    })
                } else {
                    setZoom(currZoom => {
                        if(currZoom >= ZOOM_MIN + ZOOM_DELTA) {
                            return currZoom - ZOOM_DELTA;
                        }

                        return currZoom;
                    })
                }

                setImageStyle(originalStyle => ({
                    transform: `scale(${zoom / 10})`,
                    transformOrigin: originalStyle.transformOrigin
                }))
            };
            image.current.addEventListener("wheel", onWheel, {passive: false});
            return () => image.current?.removeEventListener("wheel", onWheel, {passive: false});
        }
    }, [image, zoom, setZoom, setImageStyle]);

    return <div className="zoomed">
        Put your mouse on the image and left-click to switch between before/after image, scroll to zoom.
        <div className="zoomed-header">
            <h2>{originalTag}, {before ? "before" : "after"} image</h2>
            <Button
                variant="contained"
                disableElevation
                onClick={() => setFocusedEvent(null)}
                style={{marginLeft: '10px'}}
            > Close </Button>
        </div>
        <div className="zoomedImageContainer">
            <img
                ref={image}
                style={imageStyle}
                onClick={() => setBefore(data => !data)}
                src={`https://label-picker-trashevents.s3.eu-central-1.amazonaws.com/${focusedEvent}.${before ? "before" : "after"}.png`}
                onMouseMove={event => {
                    const x = Math.trunc((event.clientX - event.target.offsetLeft) / event.target.width * 100);
                    const y = Math.trunc((event.clientY - event.target.offsetTop) / event.target.height * 100);

                    setImageStyle({
                        transform: `scale(${zoom / 10})`,
                        transformOrigin: `${x}% ${y}%`
                    })
                }}
                onMouseLeave={() => {
                    setImageStyle({
                        transform: 'scale(1)'
                    })
                }}
            />
        </div>
    </div>;
}

export default AlternatingImage;
