import { Button, TextField } from "@mui/material";

function Result({label, setSelected, setLabel}) {
    return <div className='result'>
        <p>{label ? 'Copy the result!' : 'Result: '}</p>
        <TextField
            disabled={label ? false : true}
            className='label'
            InputProps={{
                readOnly: true,
            }}
            variant="outlined"
            value={label ? label : "Keep selecting..."}
        />

        <Button
            variant="contained"
            disableElevation
            onClick={() => {
                setSelected({
                    '_type': '',
                    '_avoidable': '',
                    '_class': '',
                    '_group': '',
                    '_subgroup': '',
                    '_description': '',
                    '_transformed': ''
                });

                setLabel('');
            }}>
            Clear
        </Button>
    </div>;
}

export default Result;